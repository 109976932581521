import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const Card = loadable(() => import('/src/components/Cards/Card'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const Image = loadable(() => import('/src/components/Media/Image'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const Comparison = ({ backgroundColour, utm }) => {
    const globalQuery = useStaticQuery(
        graphql`
            query {
                component: allWp {
                    nodes {
                        globalBlocks {
                            comparison {
                                fieldGroupName
                                comparison {
                                    addTitleArea
                                    title
                                    description
                                    addCta
                                    featureItem {
                                        title
                                        description
                                        price
                                        addCta
                                        callToAction {
                                            target
                                            title
                                            url
                                        }
                                    }
                                    callToAction {
                                        target
                                        title
                                        url
                                    }
                                    itemsRepeater {
                                        logo {
                                            id
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(height: 70)
                                                }
                                            }
                                            altText
                                            title
                                        }
                                        description
                                        price
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const [options] = useContext(OptionsContext)

    const globalData = globalQuery.component.nodes

    return globalData.map((node, index) => {
        const componentData = node.globalBlocks.comparison.comparison
        const itemRepeater = componentData.itemsRepeater

        const titleArea = {
            addTitleArea: componentData.addTitleArea,
            titlePosition: 'centered',
            title: componentData.title,
            description: componentData.description
        }

        return (
            <Section key={index} variant={backgroundColour} titleArea={titleArea} className={'c-comparison'}>
                {itemRepeater && (
                    <div className="row c-comparison__content-row">
                        {itemRepeater.map((node, index) => {
                            return (
                                <div className="col-lg-4 c-comparison__item" key={index}>
                                    <Card className={'c-comparison__item-inner'}>
                                        {node.logo && <Image data={node.logo} className={'c-comparison__item-image'} />}

                                        <div>
                                            <div className="c-comparison__item-description">{node.description}</div>
                                            <div className="c-comparison__item-description">
                                                <span className="c-comparison__item-price">{node.price}</span>
                                                per month
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            )
                        })}

                        <div className="col-lg-4 c-comparison__item c-comparison__item--feature">
                            <Card variant={'feature'} className={'c-comparison__item-inner'}>
                                <div className="c-comparison__item-title">{componentData.featureItem.title}</div>

                                <StaticImage
                                    src={'../../../../../images/pl-logotype-white.png'}
                                    height={120}
                                    alt={`${options.siteData.title} logo`}
                                    className={'c-comparison__item-image'}
                                />

                                <div className="c-comparison__item-description">
                                    Starting from only{' '}
                                    <span className="c-comparison__item-price">{componentData.featureItem.price}</span>{' '}
                                    per month
                                </div>

                                {componentData.featureItem.addCta && (
                                    <ButtonLink
                                        data={componentData.featureItem.callToAction}
                                        size={'sm'}
                                        className={'c-comparison__item-button'}
                                        utm={utm}
                                    />
                                )}
                            </Card>
                        </div>
                    </div>
                )}

                {componentData.addCta && (
                    <CtaContainer align={'centered'} className={'c-comparison__cta'}>
                        <ButtonLink data={componentData.callToAction} size={'lg'} icon={'arrow'} utm={utm} />
                    </CtaContainer>
                )}
            </Section>
        )
    })
}

Comparison.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

Comparison.defaultProps = {
    backgroundColour: 'light'
}

export default Comparison

export const query = graphql`
    fragment ComparisonForPageBuilder on WpPage_Acfpagebuilder_Layouts_Comparison {
        fieldGroupName
        backgroundColour
    }
`
